<template>
  <div style="display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;">
    <div class="
        tw-p-5
        tw-py-10
        tw-text-5xl
        tw-text-center
        tw-bg-gray-100
        tw-text-gray-700
        tw-text-lg
      ">
      404
    </div>
    <div class="
        tw-pt-3
        tw-text-gray-400
        tw-text-center
        tw-text-md
        tw-uppercase
        tw-font-normal
      ">
      Id do totem não encontrado!
    </div>
    <div class="
        tw-text-gray-400
        tw-text-center
        tw-text-md
        tw-font-normal
      ">
      Entre em contato com o adiministrador
    </div>
  </div>
</template>

<script>
export default {
  beforeMount() {
    let totemId = localStorage.getItem("totemId");
    if (totemId) {
      console.log(totemId)
      this.reconectTotem(totemId);
    } else {
      console.log('Faltando o codigo do Totem');
    }
  },
  methods: {
    reconectTotem(totemId) {
      this.$router.push("/init/" + totemId);
    }
  }
};
</script>
